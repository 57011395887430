import { Flex } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { getAccounts } from "../services/AccountsService";
import { IAccount } from "../types/IAccount";
import { Account } from "./Account";

interface AccountsProps {
  userId: string;
  onAccountSelected: (account: IAccount) => void;
}

export function Accounts({ userId, onAccountSelected }: AccountsProps) {
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
  const [accounts, setAccounts] = useState([] as IAccount[]);

  const handleAccountClick = async (account: IAccount) => {
    setSelectedAccount(account);
    onAccountSelected(account);
  };

  useEffect(() => {
    const loadAccounts = async (userId: string) => {
      const accounts = await getAccounts(userId);
      setAccounts(accounts);
      handleAccountClick(accounts[0]);
    }

    loadAccounts(userId);
  }, [userId]);

  return (
    <Flex direction="column">
      {accounts.map(account => (
        <Account account={account} isSelected={account === selectedAccount} onAccountClick={handleAccountClick} />
      ))}
    </Flex>
  );
}