import { Flex, Table, Text, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react";
import { ITransaction } from "../types/ITransaction";

export function TransactionsTable({ transactions }: { transactions: ITransaction[] }) {
  const amount = transactions.slice(0).reverse().map(transaction => transaction.amount);
  const amountsum = amount.reduce((partialSum, a) => partialSum + a / 100, 0);
  return (<Flex direction='column'>
    <Text fontSize={25} fontWeight="bold">Available Balance: £{amountsum.toFixed(2)} </Text>
    <Table variation='striped'>
      <TableHead>
        <TableRow>
          <TableCell as='th'>Date</TableCell>
          <TableCell as='th'>Description</TableCell>
          <TableCell as='th'>Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.slice(0).reverse().map(transaction =>
          <TableRow>
            <TableCell>{transaction.date.toLocaleDateString()}</TableCell>
            <TableCell>{transaction.description}</TableCell>
            <TableCell textAlign='left'>{(transaction.amount / 100).toFixed(2)}</TableCell>
          </TableRow>)}
      </TableBody>
    </Table>
  </Flex>);
}