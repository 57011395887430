import { Alert } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { getTransactions } from '../services/TransactionsService';
import { ITransaction } from '../types/ITransaction';
import { TransactionsTable } from './TransactionsTable';

export function Transactions({ accountId }: { accountId: string | undefined }) {
  const [isError, setIsError] = useState(false);
  const [transactions, setTransactions] = useState([] as ITransaction[]);

  useEffect(() => {
    const loadTransactions = async (accountId: string) => {
      try {
        const transactions = await getTransactions(accountId);
        setIsError(false);
        setTransactions(transactions);
      }
      catch (error) {
        setIsError(true);
      }
    }

    loadTransactions(accountId as string);
  }, [accountId])

  if (isError) return <Alert variation="error">Unable to load transactions</Alert>;
  else if (transactions.length === 0) return <Alert variation="info">No transactions found</Alert>;
  else return <TransactionsTable transactions={transactions} />;
}