import { Flex, Grid, Menu, MenuItem, Text, View } from '@aws-amplify/ui-react';
import { useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Accounts } from './components/Accounts';
import { Transactions } from './components/Transactions';
import { IAccount } from './types/IAccount';

function App() {
  const userId = "1";
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
  const [showArch, setShowArch] = useState(false);

  const handleAccountSelected = async (account: IAccount) => {
    console.log(account);
    setSelectedAccount(account);
  }

  return (
    <Grid gap={10}
      templateColumns="auto 1fr auto"
      templateRows="auto auto">
      <Text columnSpan={2} fontSize={50} fontWeight="bold">🏦 aBank Resilience Demo - AWS Summit</Text>
      <Flex alignItems="center" direction="row-reverse">
        <Menu menuAlign="end">
          <MenuItem onClick={() => setShowArch(true)}>Architecture Diagram</MenuItem>
        </Menu>
      </Flex>
      <Accounts userId={userId} onAccountSelected={handleAccountSelected} />
      <View columnSpan={2}>
        <Transactions accountId={selectedAccount?.accountId} />
      </View>
      <Lightbox styles={{ container: { backgroundColor: "rgba(255,255,255,0.8)" } }}
        open={showArch} close={() => setShowArch(false)}
        slides={[
          { src: require('./images/Intro.jpg') },
          { src: require('./images/Cycle.png') },
          { src: require('./images/Arch1.png') },
          { src: require('./images/Arch2.png') },
          { src: require('./images/QR.png') },
        ]} />
    </Grid>
  );
}

export default App;
