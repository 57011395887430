import { Card, Flex, Link, Text, useTheme } from "@aws-amplify/ui-react";
import { IAccount } from "../types/IAccount";

interface AccountProps {
  account: IAccount;
  isSelected: boolean;
  onAccountClick: (account: IAccount) => void;
}

export function Account({ account, isSelected, onAccountClick }: AccountProps) {
  const { tokens } = useTheme();

  return (
    <Card variation="elevated" padding={10}
      border={isSelected ? `1px solid ${tokens.colors.border.primary}` : '1px solid transparent'}>
      <Flex direction="column" rowGap="0px">
        <Link onClick={() => onAccountClick(account)}>{account.accountName}</Link>
        <Text>{account.sortCode} {account.accountNumber}</Text>
      </Flex>
    </Card >
  );
}